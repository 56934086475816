import React from "react";
import PropTypes from "proptypes";

const Header = () => {
  return (
    <header>
      <div className="container --wide">
        <h1>All Wrestling Tournaments</h1>
      </div>
    </header>
  );
};

export default Header;

Header.propTypes = {
  title: PropTypes.array
};
