import React from "react";
import ReactDOM from "react-dom";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Hits,
  SearchBox,
  Highlight,
  RefinementList
} from "react-instantsearch-dom";
import Tournament from "./Tournament";
import Header from "./Header";

export default class App extends React.Component {
  render() {
    return (
      <section>
        <Header />
        <InstantSearch
          appId="NCGYRNWX0L"
          apiKey="67646a115bb5c37567fdfc649494653d"
          indexName="prod_TOURNAMENTS"
        >
          <Search />
        </InstantSearch>
      </section>
    );
  }
}

function Search() {
  return (
    <div className="container">
      <SearchBox />
      <RefinementList attribute="wrestling_style" />
      <Hits hitComponent={Tournament} />
    </div>
  );
}
