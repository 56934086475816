import React from "react";
import PropTypes from "proptypes";

class Tournament extends React.Component {
  constructor(props) {
    super(props);
    this.state = { infoIsVisible: false };
    this.toggleInfo = this.toggleInfo.bind(this);
    this.getPartFromDate = this.getPartFromDate.bind(this);
  }

  toggleInfo() {
    this.setState({ infoIsVisible: !this.state.infoisVisible });
  }
  getPartFromDate(part) {
    let date = this.props.hit.date;
    switch (part) {
      case "day":
        return date.substring(3, 5);
      case "month":
        let num = Number(date.substring(0, 2));
        switch (num) {
          case 1:
            return "Jan";
          case 2:
            return "Feb";
          case 3:
            return "Mar";
          case 4:
            return "Apr";
          case 5:
            return "May";
          case 6:
            return "Jun";
          case 7:
            return "Jul";
          case 8:
            return "Aug";
          case 9:
            return "Sep";
          case 10:
            return "Oct";
          case 11:
            return "Nov";
          case 12:
            return "Dec";
          default:
            return "Invalid month";
        }
      case "year":
        return "20" + date.substring(6, 8);
      default:
        console.log(
          `Please provide either 'day', 'month', or 'year' as a parameter.`
        );
    }
  }

  render() {
    return (
      <a
        href={this.props.hit.pdf_url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="tournament">
          <p className="date">
            {this.getPartFromDate("month")}
            <span>{this.getPartFromDate("day")}</span>
            <span className="year">{this.getPartFromDate("year")}</span>
          </p>
          <div className="content">
            <h2>{this.props.hit.name}</h2>
            <div className="location">
              <p>{this.props.hit.location_name}</p>
              <p>{this.props.hit.location_address}</p>
            </div>
          </div>
        </div>
      </a>
    );
  }
}

export default Tournament;

Tournament.propTypes = {
  hit: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    location_name: PropTypes.string.isRequired,
    location_address: PropTypes.string.isRequired,
    start_time: PropTypes.string.isRequired,
    weigh_in_time: PropTypes.string,
    type: PropTypes.string,
    cost_pre_registered: PropTypes.string,
    cost_walk_in: PropTypes.string,
    wrestling_style: PropTypes.string.isRequired,
    age_divisions: PropTypes.string.isRequired,
    pdf_url: PropTypes.string.isRequired
  }).isRequired
};
